$(function() {
	//
	// Global
	//
	let controller = new ScrollMagic.Controller();
	const $root = $('html, body');
	let resized;
	let _windowWidth = $(window).outerWidth();

	//
	// Header
	//
	new ScrollMagic.Scene({
		triggerElement: "body",
		triggerHook: 0,
		offset: 1
	})
		.addTo(controller)
		.on("enter", function () {
			$(".rain-adapt-header").addClass("rain-adapt-header-sticky");
		})
		.on("leave", function () {
			$(".rain-adapt-header").removeClass("rain-adapt-header-sticky");
		});

	window.onresize = function () { //check for resize every 100ms on resize event to prevent spamming js
		clearTimeout(resized);
		resized = setTimeout(resizedw, 100);
	}

	function resizeWidthOnly() { //return new window width if different than previous else return false. This makes sure height changes don't trigger resize events
		let currentWindowWidth = $(window).outerWidth();
		if (_windowWidth != currentWindowWidth) {
			_windowWidth = currentWindowWidth;
			return _windowWidth;
		} else {
			return false;
		}
	}

	function resizedw() {
		if (resizeWidthOnly() !== false) {
			setArticleDetailSocialPin();
		}
	}

	//Parallax Copy
	function parallaxCopy() {
		[].slice.apply(document.querySelectorAll(".parallax-copy")).forEach(function (el) {
			let opts = {triggerElement: el, triggerHook: "onEnter", duration: "125%"},
				scene = new ScrollMagic.Scene(opts).setTween(el, {x: "-25%", ease: Linear.easeNone}).addTo(controller);
		});
	}

	//Parallax rotated items
	[].slice.apply(document.querySelectorAll(".parallax-rotated")).forEach(function (el) {
		let opts = {triggerElement: el, triggerHook: "onEnter", duration: "150%"},
			scene = new ScrollMagic.Scene(opts).setTween(el, {y: "-50%", ease: Linear.easeNone}).addTo(controller);
	});

	//Parallax background
	[].slice.apply(document.querySelectorAll(".parallax-parent")).forEach(function (el) {
		let opts = {triggerElement: el, triggerHook: "onEnter", duration: "175%"},
			scene = new ScrollMagic.Scene(opts).setTween($(el).find(".parallax-parent-img"), {
				y: "80%",
				ease: Linear.easeNone
			}).addTo(controller);

		$(el).fadeIn();
	});

	//fade up
	[].slice.apply(document.querySelectorAll(".fade-up")).forEach(function (el) {
		let opts = {triggerElement: el, triggerHook: 1, offset: 20},
			scene = new ScrollMagic.Scene(opts).addTo(controller);
		scene.on("start end", function (event) {
			el.classList.add("in-view");
		});
	});

	[].slice.apply(document.querySelectorAll(".fade-up-children")).forEach(function (el) {
		let opts = {triggerElement: el, triggerHook: 1, offset: 20},
			scene = new ScrollMagic.Scene(opts).addTo(controller);
		scene.on("start end", function (event) {
			el.classList.add("in-view");
		});
	});

	// Smooth scrolling for anchor link
	function smoothScroll() {
		$(document).on("click", 'a[href^="#"]', function () {
			if ($.attr(this, 'href').length > 1) {
				$root.animate({
					scrollTop: $($.attr(this, 'href')).offset().top - 64
				}, 300);

				hideNavMenu();
			}

			return false;
		});
	}

	smoothScroll();

	//For fixed nav background
	$(window).scroll(function () {
		setStickyHeader();
	});

	setStickyHeader();

	function setStickyHeader() {
		if ($(document).scrollTop() > 0) {
			$(".rain-vip-header").addClass("active");
		} else {
			$(".rain-vip-header").removeClass("active");
		}
	}

	//Hamburger menu click event
	$("#js-hamburger").click(function (e) {
		e.preventDefault();
		$(this).toggleClass("active");
		$(".rain-adapt-header").toggleClass("active");
	});

	//Hide Menu
	function hideNavMenu() {
		$(".rain-adapt-header-hamburger, .rain-adapt-header").removeClass("active");
	}

	//
	//Driving Growth - Pin Image
	//
	let articleDetailSocialPin = false;

	function setArticleDetailSocialPin() {
		let _elementHeight = $("#js-adapt-growth-img-pin").outerHeight();

		if (articleDetailSocialPin) {
			articleDetailSocialPin = articleDetailSocialPin.destroy(true);
		}

		if (_windowWidth > 767) {
			articleDetailSocialPin = new ScrollMagic.Scene({
				triggerElement: ".rain-adapt-growth__col-img",
				duration: $(".rain-adapt-growth__col-copy").outerHeight() - _elementHeight,
				offset: .5 + (_elementHeight / 2)
			})
				.setPin("#js-adapt-growth-img-pin")
				.addTo(controller);
		}
	}

	setArticleDetailSocialPin();


	//
	//Contact Us
	//

	//Selectmenu for normal form
	$("#js-contact-us-selectmenu").selectmenu({
		classes: {
			"ui-selectmenu-button": "placeholder"
		},
		change: function (event, ui) {
			let _thisVal = $(this).val();

			if (_thisVal === "Advertiser seeking agency partnership") {
				thankYouMessage("1");
				showNormalFormSelectMenuOptions(".rain-contact-step-2-1", ".rain-contact-step-2-2, .rain-contact-step-2-3");
			} else if (_thisVal === "Media vendor seeking partnership") {
				thankYouMessage("2");
				showNormalFormSelectMenuOptions(".rain-contact-step-2-2", ".rain-contact-step-2-1, .rain-contact-step-2-3");
			} else if (_thisVal === "Other") {
				thankYouMessage("3");
				showNormalFormSelectMenuOptions(".rain-contact-step-2-3", ".rain-contact-step-2-1, .rain-contact-step-2-2");
			}

			$("#js-contact-us-selectmenu-button").removeClass("placeholder");
			$(".rain-contact .rain-checkbox input[type=checkbox]").prop("checked", false);
		}
	});

	function thankYouMessage(_id) {
		let _thankYouCopy = $(".rain-contact-step-6").find("p");

		if (_id === "1") {
			_thankYouCopy.html("Someone from Rain the Growth Agency will contact you shortly.");
		} else if (_id === "2") {
			_thankYouCopy.html("We’ll keep your information on file and will reach out if there’s an opportunity to work together.");
		} else if (_id === "3") {
			_thankYouCopy.html("We’ll follow up with you about your question or comment.");
		}
	}

	//Selectmenu hide/show section for normal form
	function showNormalFormSelectMenuOptions(_show, _hide) {
		$(_hide).slideUp(function () {
			$(_show).slideDown();
		});
	}

	let _currentStep;
	let _nextStep;

	$(".js-contact-next").click(function (e) {
		e.preventDefault();
		let _this = $(this);
		_currentStep = _this.closest(".rain-contact-step")
		_nextStep = _this.attr("data-step")

		contactFormValidation();
	});

	$(".js-contact-prev").click(function (e) {
		e.preventDefault();
		let _this = $(this);
		_currentStep = _this.closest(".rain-contact-step")
		_nextStep = _this.attr("data-step");
		showDesiredStep();
	});

	//Validation
	function contactFormValidation() {
		let _form = $("#rain-contact-form-normal");

		_form.validate({
			errorPlacement: function (label, element) {
				if (element.is(":checkbox") || element.is(":radio")) {
					let _container = $(element).closest(".rain-contact-step-checkbox-container");
					_container.addClass("error");
					label.appendTo(_container);
				} else if (element.is("select")) {
					$(element).parent().addClass("error");
					label.insertAfter(element.parent().find(".ui-selectmenu-button"));
				} else {
					$(element).parent().addClass("error");
					label.insertAfter(element);
				}
			},
			success: function (label, element) {
				$(element).parent().removeClass("error");
				label.remove();
			},
			rules: {
				"email": {
					email: true
				},
				"interested": {
					required: true,
					minlength: 1
				},
				"vendor-type": {
					required: true,
					minlength: 1
				},
				"message": {
					required: true
				}
			},
			messages: {
				"name-first": "Oops! You skipped me",
				"name-last": "Oops! You skipped me",
				"regarding": "Oops, you skipped me! Please select one.",
				"company-name": "Oops! You skipped me.",
				"email": "This is awkward. That email's invalid.",
				"interested": "Oops, you skipped me! Please select one.",
				"vendor-type": "Oops, you skipped me! Please select one.",
				"message": "Oops! You skipped me."
			},
			submitHandler: function (form) {
				var postData = $(form).serializeArray();
				postData.push({name: 'hostname', value: document.location.hostname});

				// Google reCAPTCHA
				grecaptcha.ready(function () {
					grecaptcha.execute('6LfFWSIaAAAAABkwtFshM6P4TnY3rVFlkQoJ94ky', {action: 'jotformSubmit'}).then(function (token) {
						postData.push({name: 'token', value: token});
						postData.push({name: 'action', value: 'jotformSubmit'});

						$.ajax({
							type: "POST",
							url: "../jotform-contact-us.php",
							data: postData,
							cache: false
						}).done(function (data) {
							$(".rain-contact .rain-checkbox input[type=checkbox]").prop("checked", false);
							$(".rain-contact input, .rain-contact textarea").val("");
							//on success
							showDesiredStep();
							//console.log("ajax returned: "+data);
						}).fail(function (xhr, textStatus, errorThrown) {
							//console.log("ajax failure: " + textStatus + "|" + errorThrown);
						});
					});
				});
			}
		});

		if (_form.valid() == true) {
			//on ajax success?
			if (_nextStep === "6") {
				//makes the form submit so we can get into submithandler
				_form.submit();
			} else {
				showDesiredStep();
			}
		}
	}

	function showDesiredStep() {
		//Removes active class from pagination
		$(".rain-contact-footer-pagination-bullet").removeClass("active");

		//Hides current step
		$(_currentStep).fadeOut(function () {
			//Shows current step
			$(".rain-contact-step-" + _nextStep).fadeIn();
		});

		//Scroll to top contact module
		$root.animate({
			scrollTop: $(".rain-contact").offset().top - 64
		}, 300);
	}

	parallaxCopy();
});